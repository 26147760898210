import React, { useState } from 'react';
import './Form.css'
import Services from '../services/Services';
import CircularProgress from './CircularProgress';
import Modal from './Modal';


function Form({ token, campaignID }) {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [kvkk, setKvkk] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [followUp, setFollowUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [resultMessage, setResultMessage] = useState("Başvurunuz başarıyla alınmıştır.");

  const resetForm = () => {
    setName("");
    setSurname("");
    setPhone("");
    setEmail("");
    setKvkk(false);
    setAgreement(false);
    setFollowUp(false);
  };

  const sendApplication = (data) => {
    Services.SaveBolParaCampaignResponse(token, campaignID, data)
      .then((res) => {
        setIsLoading(false);
        setShowModal(true)
        if (res.data.status) {
          resetForm();
          setResultMessage("Başvurunuz başarıyla alınmıştır.")
        } else {
          resetForm();
          setResultMessage("Başvurunuz alınırken bir sorunla karşılaşıldı.")
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSendRequest = () => {
    setIsLoading(true);

    let data = [
      {
        name: name,
        lastname: surname,
        phone: phone,
        email: email
      }
    ];

    sendApplication(data);

    console.log('Form submitted:', {
      name,
      surname,
      phone,
      email,
      kvkk,
      agreement,
      followUp,
    });
  };

  const isFormValid = () => {
    return (
      name.trim() !== '' &&
      surname.trim() !== '' &&
      phone.trim() !== '' &&
      phone.length === 10 &&
      kvkk &&
      agreement
    );
  };

  const handlePhoneChange = (event) => {
    const input = event.target.value;
    const onlyNumbers = input.replace(/\D/g, '');
    if (onlyNumbers.length <= 10) {
      setPhone(onlyNumbers);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className='form-container'>
          <p style={{ width: '300px', fontWeight: '400', fontSize: '18px', color: '#494D59' }}>
            Easy Point Bol Para <b>- Kayıt Formu</b>
          </p>
          <p style={{ width: '300px', fontWeight: '400', fontSize: '12px', color: '#494D59' }}>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
          </p>
          <div>
            <input
              placeholder='İsim *'
              className='input'
              type='text'
              id='name'
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div>
            <input
              placeholder='Soyisim *'
              className='input'
              type='text'
              id='surname'
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
            />
          </div>
          <div>
            <input
              placeholder='Telefon *'
              className='input'
              type='tel'
              id='phone'
              value={phone}
              onChange={handlePhoneChange}
            />
          </div>
          <div>
            <input
              placeholder='E-mail'
              className='input'
              type='email'
              id='email'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className='checkBox-container'>
            <input
              type='checkbox'
              id='kvkk'
              checked={kvkk}
              onChange={() => setKvkk(!kvkk)}
            />
            <label htmlFor='kvkk'>Kvkk bilgilerini okudum. Onaylıyorum. *</label>
          </div>
          <div className='checkBox-container'>
            <input
              type='checkbox'
              id='agreement'
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
            />
            <label htmlFor='agreement'>Sözleşmeyi okudum. Onaylıyorum. *</label>
          </div>
          <div className='checkBox-container'>
            <input
              type='checkbox'
              id='followUp'
              checked={followUp}
              onChange={() => setFollowUp(!followUp)}
            />
            <label htmlFor='followUp'>
              Bol Para ile ilgili gelişmeleri e-mail yolu ile takip etmek istiyorum.
            </label>
          </div>
          <button onClick={() => handleSendRequest()} disabled={!isFormValid()}>
            <p>Hemen Başvur</p>
          </button>

        </div>
      )}

      <Modal visible={showModal}>
        <div style={{ alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', display: 'flex' }}>
          <span style={{ fontSize: '18px', fontWeight: '600' }}>Başvuru Sonucu</span>
          <p>{resultMessage}</p>
          <button style={{ marginTop: '15px' }} onClick={() => setShowModal(false)}><p>Tamam</p></button>
        </div>

      </Modal>
      <span style={{ height: '53px', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '11px', fontWeight: '500', color: '#919090' }}>
        Copyright ©Easy Point  2024.
      </span>
    </div>
  );
}

export default Form;
